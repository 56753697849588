@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap");

h1,
h2,
h3,
h4,
h5,
h6,
p,
a,
table,
button,
input,
label,
span,
div {
	font-family: Montserrat;
}

table {
	width: 100%;
	height: 100%;
	border-collapse: separate !important;
	border-spacing: 0 10px !important;
	margin: 20px 0 !important;
	min-width: 400px;
}

.request-table {
	padding-bottom: 150px;
}

.table-container {
	min-height: 40dvh;
	max-height: 150dvh;
	padding-bottom: 50px;
	/* overflow: scroll; */
}

table {
	height: 100% !important;
}

td {
	border: solid 1px #eeeef180 !important;
	border-style: solid none !important;
	background: #eeeef180 !important;
}

td:first-child {
	border-left-style: solid !important;
	border-top-left-radius: 10px !important;
	border-bottom-left-radius: 10px !important;
}

td:last-child {
	border-right-style: solid !important;
	border-bottom-right-radius: 10px !important;
	border-top-right-radius: 10px !important;
}

.auth-bg {
	background-image: url("../images/auth-bg.png");
	width: 100%;
	min-height: 100vh;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
}

.auth-card {
	max-width: 650px;
	border-top-left-radius: 15px !important;
	border-top-right-radius: 15px !important;
	border-bottom-left-radius: 0 !important;
	border-bottom-right-radius: 0 !important;
	margin: auto;
	overflow-y: auto !important;
	height: auto;
}

.auth-card-2 {
	max-width: 1009px;
	border-top-left-radius: 20px !important;
	border-top-right-radius: 20px !important;
	border-bottom-left-radius: 0 !important;
	border-bottom-right-radius: 0 !important;
	margin: auto;
	overflow-y: auto !important;
	height: 100vh;
}

.auth-card-3 {
	margin: auto;
	border: none !important;
	border-radius: 20px;
	background-color: #ffffff !important;
	width: 582px;
	height: 458px;
}

.create-project-card {
	height: 100% !important;
	border: 2px solid #d9d9d9 !important;
}

.create-new-project-card {
	width: 234px !important;
	height: 244px !important;
	border: 2px solid #d9d9d9 !important;
	background-color: #d9d9d933 !important;
}

.project-img {
	width: 100% !important;
}

.project-card {
	box-shadow: 0px 0px 2px 0px #0000001f;
	box-shadow: 0px 2px 4px 0px #00000024;
	border: none !important;
	border-radius: 20px;
	transition: background-color 0.3s;
}

.project-card:hover {
	background-color: #0f6cbd;
	color: white !important;

	.action-icon {
		filter: brightness(0) invert(1);
	}
}

.bg-color-1 {
	background-color: #0f6cbd !important;
}

.bg-color-2 {
	background-color: #f9f8f8 !important;
	display: grid;
	place-items: center;
	width: 100%;
	height: 100vh;
}

.bg-color-3 {
	background-color: #ebf2fd !important;
}

.bg-color-4 {
	background-color: transparent !important;
}

.bg-color-5 {
	background-color: #0f6cbd1a !important;
}

.bg-color-6 {
	background-color: #6cbd0f1a !important;
}

.bg-color-7 {
	background-color: #bd0f6c1a !important;
}

.bg-color-8 {
	background-color: #f5f7fa !important;
}

.bg-color-9 {
	background-color: #ffffff !important;
}

.bg-color-10 {
	background-color: #e0821333 !important;
}

.text-color-1 {
	color: #0f6cbd !important;
}

.text-color-2 {
	color: #747487 !important;
}

.text-color-3 {
	color: #242424 !important;
}

.text-color-4 {
	color: #0f6cbd !important;
}

.text-color-5 {
	color: #6cbd0f !important;
}

.text-color-6 {
	color: #bd0f6c !important;
}

.text-color-7 {
	color: #383861 !important;
}

.text-color-8 {
	color: #e08213 !important;
}

.text-color-9 {
	color: #e01313b2 !important;
}

.text-color-10 {
	color: #187e2280 !important;
}

.text-color-11 {
	color: #0f6cbd !important;
}

.text-color-12 {
	color: #6cbd0f !important;
}

.text-color-13 {
	color: #e08213b2 !important;
}

.text-color-14 {
	color: #008000 !important;
}

.timeline-dot-initial {
	color: #0f6cbd !important;
}

.timeline-dot-reviewed {
	color: #0f6cbd !important;
}

.timeline-dot-approved {
	color: #0f6cbd !important;
}

.sidebar-company-card {
	border: 1px solid #eeeef1 !important;
	width: 240px !important;
	height: 60px !important;
	padding-top: 8px !important;
	padding-left: 10px !important;
	padding-right: 10px !important;
	margin-left: 10px !important;
	margin-top: 100px !important;
}

.dash-texts-1 {
	font-weight: 600;
	font-size: 48px;
}

.dash-texts-2 {
	font-weight: 550;
	font-size: 16px;
}

.dash-texts-3 {
	font-weight: 600;
	font-size: 14px;
}

.dash-texts-4 {
	font-weight: 500;
	font-size: 14px;
}

.dash-texts-5 {
	font-weight: 550 !important;
	font-size: 35px !important;
}

.dash-texts-6 {
	font-weight: 500;
	font-size: 9px;
}

.dash-texts-7 {
	font-weight: 500;
	font-size: 11px;
}

.dash-border {
	border-left: 2px solid #0f6cbd33;
	height: 58px;
	padding-right: 1rem;
}

.add-btn {
	background-color: transparent !important;
	color: black;
	border: none !important;
}

.link-styles {
	cursor: pointer;
	text-decoration: none;
	color: #000000;
}

.dropdown-toggle::after {
	display: none !important;
}

.fluent-input {
	width: 100%;
	height: 56px !important;
	padding: 0.375rem 0.2rem;
	font-size: 1rem;
	line-height: 1.5;
	border: 1px solid lightgray;
	outline: 0;
	border-radius: 5px;
	appearance: none;
	transition: border-width 0.25s ease-in-out, box-shadow 0.15s ease-in-out;
}

.fluent-input:focus {
	border-width: 1px 1px 2px 1px;
	border-color: gray gray #0f6cbd gray;
	background: rgb(248, 248, 248);
}

.is-invalid {
	border: 1px solid red;
}

.sidebar {
	flex: 0 0 260px;
	height: 100vh;
	transition: transform 0.3s ease-in-out;
	/* box-shadow: 8px 0px 19px -4px rgba(36, 71, 97, 0.75); */
}

.sidebar.closed {
	transform: translateX(-100%);
}

.sidebar.open {
	transform: translateX(0);
}

.hamburger {
	position: fixed;
	margin-top: 95px !important;
	left: 15px;
	background: none;
	border: none;
	cursor: pointer;
	z-index: 1000;
}

.settings-hamburger {
	position: fixed;
	margin-top: 15px !important;
	left: 15px;
	background: none;
	border: none;
	cursor: pointer;
	z-index: 1000;
}

@media (max-width: 1320px) {
	.sidebar {
		position: fixed;
		top: 0;
		left: 0;
		height: 100%;
		background-color: #fff;
		z-index: 999;
	}
}

.sidebar-link {
	display: block;
	line-height: 30px;
	color: #747487;
	transition: 0.5s;
	width: Fixed (224px) px;
	height: Fixed (50px) px;
	padding: 10px;
	border-radius: 4px 0px 0px 0px;
	opacity: 1;
	cursor: pointer;
	text-decoration: none;
	font-size: 14px;
	font-weight: 600;
	text-align: left;
}

.bg-color-11 {
	background-color: #0f6cbd59;
}

.bg-color-12 {
	background-color: #0f6cbd;
}

.sidebar-link:hover,
.sidebar-link.active {
	color: #0f6cbd !important;
	text-decoration: none;
	background-color: #ebf2fd !important;
}

.sidebar-text {
	font-size: 13px !important;
}

.sidebar-text-1 {
	font-size: 10px !important;
	font-weight: 600;
	color: #747487;
}

.sidebar-link:hover .sidebar-text,
.sidebar-link.active .sidebar-text,
.sidebar-link:hover .sidebar-icon,
.sidebar-link.active .sidebar-icon {
	color: #0f6cbd !important;
}

.sidebar-icon {
	margin-right: 25px !important;
}

.sidebar-menu {
	padding-top: 30px !important;
	padding-left: 10px !important;
	padding-right: 10px !important;
	gap: 30px !important;
}

.active-side {
	color: #ebf2fd !important;
}

.settings-sidebar-link {
	display: block;
	line-height: 30px;
	color: #747487;
	transition: 0.5s;
	width: Fixed (224px) px;
	height: Fixed (50px) px;
	padding: 10px;
	border-radius: 4px 0px 0px 0px;
	opacity: 1;
	cursor: pointer;
	text-decoration: none;
	font-size: 14px;
	font-weight: 600;
	text-align: left;
}

.settings-sidebar-text {
	font-size: 14px !important;
}

.settings-sidebar-link:hover .settings-sidebar-text,
.settings-sidebar-link.active .settings-sidebar-text {
	color: #0f6cbd !important;
	border: 2px solid #0f6cbd !important;
}

@media (max-width: 1320px) {
	.settings-layout {
		flex-direction: column;
	}

	.sidebar {
		flex: 0 0 100%;
	}

	.sidebar-link {
		width: 100%;
		height: auto;
		border-radius: 0;
	}

	.sidebar-menu {
		padding-top: 20px;
	}
}

.settings-layout {
	display: flex;
}

.content {
	flex: 4 !important;
	overflow: auto;
	box-sizing: border-box;
	height: 100vh;
	width: 100%;
}

.main-layout {
	display: flex;
	flex-direction: column;
	min-height: 100vh;
}

.layout-content {
	display: flex;
	flex-grow: 1;
}

.faded {
	opacity: 0.5;
}

/* frequently used generic styles */
.w-fc {
	width: fit-content;
}

.w-5 {
	width: 5%;
}

.w-10 {
	width: 10%;
}

.w-15 {
	width: 15%;
}

.w-20 {
	width: 20%;
}

.w-25 {
	width: 25%;
}

.w-30 {
	width: 30%;
}

.w-33 {
	width: 33%;
}

.w-35 {
	width: 35%;
}

.w-40 {
	width: 40%;
}

.w-45 {
	width: 45%;
}

.w-50 {
	width: 50%;
}

.w-55 {
	width: 55%;
}

.w-60 {
	width: 60%;
}

.w-65 {
	width: 65%;
}

.w-70 {
	width: 70%;
}

.w-75 {
	width: 75%;
}

.w-80 {
	width: 80%;
}

.w-85 {
	width: 85%;
}

.w-90 {
	width: 90%;
}

.w-95 {
	width: 95%;
}

.w-100 {
	width: 100%;
}

@media (max-width: 600px) {
	.w-fc-s {
		width: fit-content;
	}

	.w-5-s {
		width: 5%;
	}

	.w-10-s {
		width: 10%;
	}

	.w-15-s {
		width: 15%;
	}

	.w-20-s {
		width: 20%;
	}

	.w-25-s {
		width: 25%;
	}

	.w-30-s {
		width: 30%;
	}

	.w-33-s {
		width: 33%;
	}

	.w-35-s {
		width: 35%;
	}

	.w-40-s {
		width: 40%;
	}

	.w-45-s {
		width: 45%;
	}

	.w-50-s {
		width: 50%;
	}

	.w-55-s {
		width: 55%;
	}

	.w-60-s {
		width: 60%;
	}

	.w-65-s {
		width: 65%;
	}

	.w-70-s {
		width: 70%;
	}

	.w-75-s {
		width: 75%;
	}

	.w-80-s {
		width: 80%;
	}

	.w-85-s {
		width: 85%;
	}

	.w-90-s {
		width: 90%;
	}

	.w-95-s {
		width: 95%;
	}

	.w-100-s {
		width: 100%;
	}
}

@media (min-width: 601px) and (max-width: 1000px) {
	.w-fc-m {
		width: fit-content;
	}

	.w-5-m {
		width: 5%;
	}

	.w-10-m {
		width: 10%;
	}

	.w-15-m {
		width: 15%;
	}

	.w-20-m {
		width: 20%;
	}

	.w-25-m {
		width: 25%;
	}

	.w-30-m {
		width: 30%;
	}

	.w-33-m {
		width: 33%;
	}

	.w-35-m {
		width: 35%;
	}

	.w-40-m {
		width: 40%;
	}

	.w-45-m {
		width: 45%;
	}

	.w-50-m {
		width: 50%;
	}

	.w-55-m {
		width: 55%;
	}

	.w-60-m {
		width: 60%;
	}

	.w-65-m {
		width: 65%;
	}

	.w-70-m {
		width: 70%;
	}

	.w-75-m {
		width: 75%;
	}

	.w-80-m {
		width: 80%;
	}

	.w-85-m {
		width: 85%;
	}

	.w-90-m {
		width: 90%;
	}

	.w-95-m {
		width: 95%;
	}

	.w-100-m {
		width: 100%;
	}
}

.cancel-btn {
	color: #0f6cbd !important;
	background-color: white !important;
	border: 2px solid #0f6cbd;
	height: 56px !important;
	width: 153px !important;
	cursor: pointer;
}

.create-btn {
	color: white;
	cursor: pointer;
	height: 56px !important;
	width: 153px !important;
}

.add-more-btn {
	width: 126px !important;
	height: 32px !important;
	color: white;
	background-color: #0f6cbd !important;
	padding: 5px, 12px, 5px, 12px;
}

.budget-btn {
	width: 199px !important;
	height: 56px !important;
}

.logout-btn {
	background-color: white !important;
	color: black !important;
}

.btn {
	color: white;
	cursor: pointer;
}

.btn:hover {
	background-color: #0f6cbd7f !important;
}

.pointer {
	cursor: pointer;
}

.w-fit {
	width: fit-content;
}

.settings-nav,
.nav {
	background: #ebf2fd;
	width: fit-content;
	border-radius: 5px;
}

.bg-green {
	background: green;
}

.error-message {
	color: red;
	font-size: 0.75rem;
}

.error-message-placeholder {
	color: transparent;
	font-size: 0.75rem;
}

.rectangle {
	border: 1px solid #d9d9d9;
	border: 1px solid #d9d9d9;
}

.col-separator {
	border-right: 3px solid #6a6a89;
	padding-right: 1rem;
}

.col-separator:last-child {
	border-right: none;
}

.modal-container {
	position: fixed !important;
	top: 70% !important;
	left: 50% !important;
	transform: translate(-50%, -50%) !important;
	width: 100% !important;
	height: 100% !important;
}

.confirm-modal {
	position: fixed !important;
	top: 80% !important;
	left: 50% !important;
	transform: translate(-50%, -50%) !important;
	width: 612px !important;
	height: 100% !important;
}

.confirm-header {
	font-size: 20px;
	font-weight: 600;
	line-height: 20px;
	text-align: left;
	padding-top: 10px;
}

.confirm-body {
	font-size: 18px;
	font-weight: 500;
	line-height: 20px;
	text-align: left;
	color: #707070 !important;
}

td {
	font-size: 14px !important;
}

#view-store-request-table td {
	background: transparent !important;
	border: none !important;
}

h3 {
	font-size: 24px !important;
	font-weight: 600 !important;
}

h4 {
	font-size: 20px !important;
	font-weight: 600 !important;
}

.report-text-1 {
	font-size: 16px;
	font-weight: 800;
}

.report-text-2 {
	font-weight: 600 !important;
}

.report-text-3 {
	font-size: 16px;
	font-weight: 500;
}

.report-text-4 {
	font-size: 10px;
	font-weight: 500;
	color: #0f6cbd;
}

.report-text-5 {
	font-size: 12px;
	font-weight: 600;
	color: #616161;
}

.report-text-6 {
	font-size: 12px;
	font-weight: 600;
	color: #747487;
}

.report-text-7 {
	font-size: 10px;
	font-weight: 600;
	color: #383861;
}

.report-text-8 {
	font-size: 12px;
	font-weight: 600;
	color: #747487;
}

.report-text-10 {
	font-size: 10px;
	font-weight: 500;
}

.report-text-11 {
	font-size: 9px;
	font-weight: 400;
}

.report-pie-chart {
	height: 100%;
	width: 100%;
	min-height: 180px;
}

.report-progress-bar {
	height: 8px !important;
	border-radius: 6px;
	width: 100%;
}

.report-nav {
	border: 2px solid #eeeef1;
	width: 100%;
}

.text-transparent {
	color: transparent;
}

.date-border {
	border: 1px solid #eeeef1;
	padding-top: 5px;
	padding-bottom: 5px;
	border-radius: 5px;
	width: 100%;
	height: 100%;
}

@media (min-width: 760px) and (max-width: 930px) {
	.responsive-col {
		flex: 0 0 100%;
		max-width: 100%;
	}
}